import React from "react";
import './CSS/Page6.css'; // Ensure CSS file for styling is present
import videoSrc from './Video/travel4.mp4'; // Update the path as needed

function Page6() {
  return (
    <div className="video-container">
      <h2 className="video-title">Explore the World</h2>
      <div className="video-frame">
        <video className="video-player" autoPlay loop muted>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="content-section">
        <h3 className="section-title">About Us</h3>
        <p className="section-text">
          At <strong>OMAC GROUPS</strong>, we believe that travel is more than just a journey – it's a life-changing experience. Our mission is to provide exceptional travel services that allow you to discover the world’s most enchanting destinations.
        </p>
        <p className="section-text">
          With decades of expertise in the industry, <strong>OMAC GROUPS</strong> strives to offer personalized, high-quality travel experiences tailored to your desires. Whether you're seeking relaxation on serene beaches or adventure in the heart of bustling cities, we make it happen.
        </p>
        <p className="section-text">
          Travel is a transformative and enriching experience that allows individuals to explore new cultures, landscapes, and history. At <strong>OMAC GROUPS</strong>, we are proud to be part of your journey, offering not only trips but lifelong memories.
        </p>
        
        <h3 className="discover-title">Discover More</h3>
        <p className="discover-text">
          50 Reasons To Visit Sri Lanka
        </p>
      </div>
    </div>
  );
}

export default Page6;
