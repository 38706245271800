import { initializeApp } from "firebase/app";
import { collection, query, orderBy, limit, getDocs, setDoc, doc } from "firebase/firestore"; 
import { db } from "../../../model/FirebaseConfig";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import "@fontsource/roboto";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import omaclogo from "../../Assets/OMACLOGO.png";
import confettiEffect from "../../Confetti/confettiEffect";

// Function to generate a unique ID for each document
const generateUniqueId = async () => {
  const tailorMadeCollection = collection(db, "TailorMade");
  const q = query(tailorMadeCollection, orderBy("id", "desc"), limit(1)); // Get the most recent document
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    // Start with 001 if no documents exist
    return "Web-TM-001";
  }

  // Extract the latest ID and increment it
  const lastDocId = querySnapshot.docs[0].data().id;
  const lastIdNumber = parseInt(lastDocId.split("-").pop(), 10); // Extract the numeric part
  const incrementedIdNumber = lastIdNumber + 1;

  // Format the new ID with leading zeros
  return `Web-TM-${String(incrementedIdNumber).padStart(3, "0")}`;
};

// Main handler for form submission
export const handleSubmit = async (formData) => {
  try {
    // Generate a unique ID for the new document
    const newId = await generateUniqueId();

    // Add document to Firestore with the new ID using setDoc
    const tailorMadeDocRef = doc(db, "TailorMade", newId); // Specify custom document ID
    await setDoc(tailorMadeDocRef, {
      ...formData,
      id: newId,
      timestamp: new Date(),
    });

    const pdfBlob = await generatePDF(formData, newId);
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = `${formData.name} TailorMade Receipt.pdf`;
    link.click();
    
    // await sendEmail(formData, pdfBlob);

    // Trigger confetti effect
    confettiEffect({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }, // Start from slightly above the popup
    });

    Swal.fire({
      icon: "success",
      title: "Tailer-Made Inquiry Submitted!",
      html: `
        <div class="receipt">
          <div class="receipt-header">
            <h2 class="company-name">OMAC GROUPS</h2>
            <p>Inquiry Receipt</p>
          </div>
          <div class="receipt-body">
            <p><strong>Name:</strong> ${formData.name || "N/A"}</p>
            <p><strong>Inquiry ID:</strong> ${newId}</p>
            <p><strong>Date:</strong> ${new Date().toLocaleString()}</p>
          </div>
          <div class="receipt-footer">
            <p>Thank you for reaching out to us!</p>
            <p>We'll get back to you soon.</p>
          </div>
        </div>
      `,
      confirmButtonText: "OK",
      customClass: {
        popup: "receipt-popup",
      },
      showCloseButton: true,
      showConfirmButton: true,
      timer: 50000,
      timerProgressBar: true,
      didOpen: () => {
        // Adding custom styles for SweetAlert2 popup
        const style = document.createElement("style");
        style.textContent = `
          .receipt-popup {
            background-color: #f8f9fa;
            border-radius: 15px;
            box-shadow: 0 0 20px rgba(0,0,0,0.1);
          }
          .receipt {
            background-color: white;
            border-radius: 10px;
            padding: 20px;
            box-shadow: 0 0 10px rgba(0,0,0,0.05);
          }
          .receipt-header {
            text-align: center;
            border-bottom: 2px dashed #e9ecef;
            padding-bottom: 10px;
            margin-bottom: 15px;
          }
          .receipt-header h2.company-name {
            color: #28a745;
            font-size: 24px;
            margin: 0;
            font-weight: bold;
          }
          .receipt-body {
            margin-bottom: 15px;
          }
          .receipt-body p {
            margin: 5px 0;
            font-size: 14px;
          }
          .receipt-footer {
            text-align: center;
            border-top: 2px dashed #e9ecef;
            padding-top: 10px;
            font-size: 14px;
          }
        `;
        document.head.appendChild(style);
      },
    });
    

    return true;
  } catch (error) {
    // Log detailed error for debugging
    console.error("Error adding document: ", error);

    // Extract error message
    const errorMessage = error instanceof Error ? error.message : "An unknown error occurred";

    Swal.fire({
      title: "Error!",
      text: `There was an error submitting your form. Please try again. ${errorMessage}`,
      icon: "error",
      confirmButtonText: "OK",
    });

    return false;
  }
};

export const generatePDF = (formData, inquiryId) => {
  return new Promise((resolve) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;

    // Set font to Roboto
    doc.setFont("Roboto", "normal");

    // Header Section with Logo
    const logoWidth = 100;
    const logoHeight = 10;
    doc.addImage(
      omaclogo,
      "PNG",
      (pageWidth - logoWidth) / 2,
      10,
      logoWidth,
      logoHeight
    );

    // Subtitle Section
    doc.setFont("roboto", "normal"); // Set to a bold style for subtitle visibility
    doc.setFontSize(12); // Adjust font size for subtitle
    doc.setTextColor(34, 85, 85); // Set a color (e.g., dark teal)
    doc.text("Tailor-Made Confirmation Receipt", pageWidth / 2, 25, {
      align: "center",
    });

    // Draw a line under the logo
    doc.setDrawColor(34, 85, 85);
    doc.setLineWidth(0.5);
    doc.line(10, 35, pageWidth - 10, 35);

    // Welcome Message
    let cursorY = 45;
    doc.setFontSize(10);
   const welcomeMessage = `Dear ${formData.name},

Thank you for reaching out to us through the OMAC web platform. We are delighted to have the opportunity to assist you with your travel plans. Our team is already reviewing your request, and we will get back to you as soon as possible to make your experience memorable and stress-free.

Your inquiry ID is ${inquiryId}. Please reference this ID in any future communications regarding this inquiry.

Meanwhile, please find the details of your inquiry below. If you have any immediate questions, feel free to reach out to us. We are here to make every step of your journey special!`;

    const welcomeLines = doc.splitTextToSize(welcomeMessage, pageWidth - 30);
    doc.text(welcomeLines, 10, cursorY);
    cursorY += welcomeLines.length * 3.5 + 10; // Adjust cursor for next section

    // Contact Information Section
    doc.setFont("times", "bold");
    doc.setFontSize(12);
    doc.text("Contact Information", 14, cursorY);
    cursorY += 5;

    doc.autoTable({
      startY: cursorY,
      margin: { left: 14 },
      headStyles: { fillColor: [34, 85, 85] },
      bodyStyles: { textColor: [34, 85, 85] },
      alternateRowStyles: { fillColor: [240, 240, 240] },
      head: [["Field", "Value"]],
      body: [
        ["Inquiry ID", inquiryId],
        ["Name", formData.name],
        ["Email", formData.email],
        ["Country", formData.country],
        ["Phone", formData.phone],
        ["Address", formData.address],
      ],
      willDrawCell: function (data) {
        if (
          data.row.section === "body" &&
          cursorY + data.row.height > pageHeight - 20
        ) {
          doc.addPage();
          cursorY = 10;
        }
      },
    });
    cursorY = doc.lastAutoTable.finalY + 10;

    // Tour Information Section
    doc.setFontSize(12);
    doc.text("Tour Information", 14, cursorY);
    cursorY += 5;

    doc.autoTable({
      startY: cursorY,
      margin: { left: 14 },
      headStyles: { fillColor: [34, 85, 85] },
      bodyStyles: { textColor: [34, 85, 85] },
      alternateRowStyles: { fillColor: [240, 240, 240] },
      head: [["Field", "Value"]],
      body: [
        ["Arrival Date", formData.arrivalDate],
        ["Departure Date", formData.departureDate],
        ["Flexibility", formData.flexibility],
        ["Adults", formData.adults],
        ["Children", formData.children],
        ["Tour Area", formData.tourArea],
      ],
    });
    cursorY = doc.lastAutoTable.finalY + 10;

    // Interests Section
    doc.setFontSize(12);
    doc.text("Your Interests", 14, cursorY);
    cursorY += 5;

    // Check if there's enough space; if not, add a new page
    if (cursorY + 30 > pageHeight - 30) {
      doc.addPage();
      cursorY = 20;
    }

    // Handle large comments text
    const additionalComments = formData.additionalComments || "";
    const formattedComments =
      additionalComments.length > 100
        ? additionalComments.match(/.{1,100}/g).join("\n") // Break long text
        : additionalComments;

    doc.autoTable({
      startY: cursorY,
      margin: { left: 14 },
      headStyles: { fillColor: [34, 85, 85] },
      bodyStyles: { textColor: [34, 85, 85] },
      alternateRowStyles: { fillColor: [240, 240, 240] },
      head: [["Field", "Value"]],
      body: [
        ["Accommodation", formData.accommodation],
        ["Interests", formData.interests.join(", ")],
        ["Additional Comments", formattedComments],
      ],
      didDrawCell: function (data) {
        if (
          data.row.section === "body" &&
          cursorY + data.row.height > pageHeight - 20
        ) {
          doc.addPage();
          cursorY = 10;
        }
      },
    });
    cursorY = doc.lastAutoTable.finalY + 10;

    // Footer Section
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(`Page ${i} of ${pageCount}`, pageWidth / 2, 287, {
        align: "center",
      });

      if (i === pageCount) {
        if (cursorY + 20 > pageHeight - 30) {
          doc.addPage();
          cursorY = 20;
        }
        doc.setFont("times", "bold");
        doc.setFontSize(10);
        doc.setTextColor(34, 85, 85);
        doc.text(
          "THANK YOU FOR CONTACTING OMAC GROUPS",
          pageWidth / 2,
          cursorY + 10,
          { align: "center" }
        );

        doc.setFont("times", "normal");
        doc.setFontSize(9);
        doc.text(
          `We appreciate your interest and will get back to you soon.\nIf you have any urgent questions, please don't hesitate to call us at:\n\n031 222 4888 / 070 750 4888\n\n© 2023 OMAC GROUPS. All rights reserved.\n\nThis is an automated message. Please do not reply to this email.`,
          pageWidth / 2,
          cursorY + 30,
          { align: "center" }
        );
      }
    }

    // Finalize and Resolve the PDF
    const pdfBlob = doc.output("blob");
    resolve(pdfBlob);
  });
};

export const sendEmail = async (formData, pdfBlob) => {
  try {
    // Convert blob to base64
    const reader = new FileReader();
    reader.readAsDataURL(pdfBlob);
    reader.onloadend = async function () {
      const base64data = reader.result;

      const templateParams = {
        to_email: formData.email,
        from_name: "TailorMade Tours",
        to_name: formData.name,
        message:
          "Thank you for submitting your tour preferences. Please find attached your TailorMade Tour Report.",
        file: base64data,
      };

      await emailjs.send(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        templateParams,
        "YOUR_USER_ID"
      );

      // Send to omacgroups@gmail.com
      const adminTemplateParams = {
        ...templateParams,
        to_email: "omacgroups@gmail.com",
        to_name: "Admin",
      };

      await emailjs.send(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        adminTemplateParams,
        "YOUR_USER_ID"
      );

      Swal.fire({
        title: "Email Sent!",
        text: "Your TailorMade Tour Report has been sent to your email.",
        icon: "success",
        confirmButtonText: "OK",
      });
    };
  } catch (error) {
    console.error("Error sending email: ", error);
    Swal.fire({
      title: "Error!",
      text: "There was an error sending the email. Please try again.",
      icon: "error",
      confirmButtonText: "OK",
    });
  }
};
