import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  ProgressBar,
  Card,
} from "react-bootstrap";
import { FaCheck, FaPlaneDeparture, FaGlobe } from "react-icons/fa";
import {
  handleSubmit,
} from "./utilities/TailorMadeHandler";


const TailorMade = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    country: "",
    phone: "",
    address: "",
    arrivalDate: "",
    departureDate: "",
    flexibility: "",
    adults: "",
    children: "",
    tourArea: [],
    accommodation: "",
    interests: [],
    additionalComments: "",
  });

  const [progress, setProgress] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]:
        type === "checkbox"
          ? checked
            ? [...prevState[name], value]
            : prevState[name].filter((item) => item !== value)
          : value,
    }));
  };

  useEffect(() => {
    const filledFields = Object.values(formData).filter(
      (value) => value !== "" && value.length !== 0
    ).length;
    const totalFields = Object.keys(formData).length;
    setProgress((filledFields / totalFields) * 100);
  }, [formData]);


  const onSubmit = async (e) => { setIsSubmitting(true)
    e.preventDefault();
    const success = await handleSubmit(formData);
    if (success) {
      setFormData({
        name: "",
        email: "",
        country: "",
        phone: "",
        address: "",
        arrivalDate: "",
        departureDate: "",
        flexibility: "",
        adults: "",
        children: "",
        tourArea: "",
        accommodation: "",
        interests: [],
        additionalComments: "",
      });

    }
    setIsSubmitting(false)
  };

  const isFieldFilled = (field) =>
    formData[field] !== "" && formData[field].length !== 0;

  return (
    <Container className="my-5 bg-opacity-75 p-4 rounded shadow-lg position-relative z-10">
      <Card className="shadow-lg border-0">
        <Card.Body className="p-5">
          <h1 className="text-center mb-4 text-primary display-4 fw-bold">
            Your Journey <FaPlaneDeparture className="me-2 text-success" /> ,
            Your Way <FaGlobe className="ms-2 text-success" />
          </h1>
          <ProgressBar
            now={progress}
            label={`${Math.round(progress)}%`}
            className="mb-4"
            variant="success"
          />
          <Form onSubmit={onSubmit}>
            <h3 className="text-secondary">Section 1 - Contact Information</h3>
            <Row className="mb-4">
              <Col md={6}>
                <Form.Group controlId="name">
                  <Form.Label className="fw-bold">Name</Form.Label>
                  <div className="input-group">
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      className="rounded-pill"
                      required
                    />
                    {isFieldFilled("name") && (
                      <span className="input-group-text bg-transparent border-0">
                        <FaCheck className="text-success" />
                      </span>
                    )}
                  </div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="email">
                  <Form.Label className="fw-bold">Email</Form.Label>
                  <div className="input-group">
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      className="rounded-pill"
                      required
                    />
                    {isFieldFilled("email") && (
                      <span className="input-group-text bg-transparent border-0">
                        <FaCheck className="text-success" />
                      </span>
                    )}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={6}>
                <Form.Group controlId="country">
                  <Form.Label className="fw-bold">Country</Form.Label>
                  <div className="input-group">
                    <Form.Control
                      type="text"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      placeholder="Enter your country"
                      className="rounded-pill"
                      required
                    />
                    {isFieldFilled("country") && (
                      <span className="input-group-text bg-transparent border-0">
                        <FaCheck className="text-success" />
                      </span>
                    )}
                  </div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="phone">
                  <Form.Label className="fw-bold">Phone</Form.Label>
                  <div className="input-group">
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                      className="rounded-pill"
                      required
                    />
                    {isFieldFilled("phone") && (
                      <span className="input-group-text bg-transparent border-0">
                        <FaCheck className="text-success" />
                      </span>
                    )}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Form.Group controlId="address">
                  <Form.Label className="fw-bold">Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    placeholder="Enter your address"
                    className="rounded"
                    rows={3}
                    required
                  />
                  {isFieldFilled("address") && (
                    <span className="d-block text-success mt-1">
                      <FaCheck />
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <h3 className="text-secondary">Section 2 - Tour Information</h3>
            <Row className="mb-4">
              <Col md={6}>
                <Form.Group controlId="arrivalDate">
                  <Form.Label className="fw-bold">Arrival Date</Form.Label>
                  <div className="input-group">
                    <Form.Control
                      type="date"
                      name="arrivalDate"
                      value={formData.arrivalDate}
                      onChange={handleChange}
                      className="rounded-pill"
                      required
                    />
                    {isFieldFilled("arrivalDate") && (
                      <span className="input-group-text bg-transparent border-0">
                        <FaCheck className="text-success" />
                      </span>
                    )}
                  </div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="departureDate">
                  <Form.Label className="fw-bold">Departure Date</Form.Label>
                  <div className="input-group">
                    <Form.Control
                      type="date"
                      name="departureDate"
                      value={formData.departureDate}
                      onChange={handleChange}
                      className="rounded-pill"
                      required
                    />
                    {isFieldFilled("departureDate") && (
                      <span className="input-group-text bg-transparent border-0">
                        <FaCheck className="text-success" />
                      </span>
                    )}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={6}>
                <Form.Group controlId="flexibility">
                  <Form.Label className="fw-bold">
                    Flexibility on dates
                  </Form.Label>
                  <div>
                    <Form.Check
                      inline
                      type="radio"
                      label="Yes"
                      name="flexibility"
                      value="Yes"
                      checked={formData.flexibility === "Yes"}
                      onChange={handleChange}
                      required
                      className="form-check-inline"
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="No"
                      name="flexibility"
                      value="No"
                      checked={formData.flexibility === "No"}
                      onChange={handleChange}
                      required
                      className="form-check-inline"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="adults">
                  <Form.Label className="fw-bold">No of Adults</Form.Label>
                  <div className="input-group">
                    <Form.Control
                      type="number"
                      name="adults"
                      value={formData.adults}
                      onChange={handleChange}
                      placeholder="Number"
                      className="rounded-pill"
                      required
                    />
                    {isFieldFilled("adults") && (
                      <span className="input-group-text bg-transparent border-0">
                        <FaCheck className="text-success" />
                      </span>
                    )}
                  </div>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="children">
                  <Form.Label className="fw-bold">
                    Number of Children
                  </Form.Label>
                  <div className="input-group">
                    <Form.Control
                      type="number"
                      name="children"
                      value={formData.children}
                      onChange={handleChange}
                      placeholder="Number"
                      className="rounded-pill"
                      required
                    />
                    {isFieldFilled("children") && (
                      <span className="input-group-text bg-transparent border-0">
                        <FaCheck className="text-success" />
                      </span>
                    )}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Form.Group controlId="tourArea">
                  <Form.Label className="fw-bold">Tour Area</Form.Label>
                  <div>
                    {[
                      "Colombo",
                      "Kandy",
                      "Galle",
                      "Sigiriya",
                      "Nuwara Eliya",
                      "Yala",
                      "Trincomalee",
                      "Other",
                    ].map((tourArea) => (
                      <Form.Check
                        key={tourArea}
                        inline
                        type="checkbox"
                        label={tourArea}
                        name="tourArea"
                        value={tourArea}
                        checked={formData.tourArea.includes(tourArea)}
                        onChange={handleChange}
                        className="form-check-inline"
                      />
                    ))}
                     </div>
                     {isFieldFilled("tourArea") && (
                      <span className="input-group-text bg-transparent border-0">
                        <FaCheck className="text-success" />
                      </span>
                    )}
                </Form.Group>
              </Col>
            </Row>

            <h3 className="text-secondary">Section 3 - Your Interest</h3>
            <Row className="mb-4">
              <Col>
                <Form.Group controlId="accommodation">
                  <Form.Label className="fw-bold">Accommodation</Form.Label>
                  <div className="input-group">
                    <Form.Control
                      as="select"
                      name="accommodation"
                      value={formData.accommodation}
                      onChange={handleChange}
                      className="rounded-pill"
                      required
                    >
                      <option value="">Select Accommodation Type</option>
                      <option value="Villa">Villa</option>
                      <option value="Boutique">Boutique</option>
                      <option value="Deluxe">Deluxe</option>
                      <option value="Superior">Superior</option>
                      <option value="Fit to my Budget">Fit to my Budget</option>
                    </Form.Control>
                    {isFieldFilled("accommodation") && (
                      <span className="input-group-text bg-transparent border-0">
                        <FaCheck className="text-success" />
                      </span>
                    )}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Form.Group controlId="interests">
                  <Form.Label className="fw-bold">Your Interests</Form.Label>
                  <div>
                    {[
                      "Adventure",
                      "Rest and Relaxation",
                      "Wildlife and Nature",
                      "Golf",
                      "Water Activities",
                    ].map((interest) => (
                      <Form.Check
                        key={interest}
                        inline
                        type="checkbox"
                        label={interest}
                        name="interests"
                        value={interest}
                        checked={formData.interests.includes(interest)}
                        onChange={handleChange}
                        className="form-check-inline"
                      />
                    ))}
                  </div>
                  {isFieldFilled("interests") && (
                      <span className="input-group-text bg-transparent border-0">
                        <FaCheck className="text-success" />
                      </span>
                    )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Form.Group controlId="additionalComments">
                  <Form.Label className="fw-bold">
                    Additional Comments
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    name="additionalComments"
                    value={formData.additionalComments}
                    onChange={handleChange}
                    placeholder="Enter any additional information or comments here"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button
              variant="success"
              type="submit"
              className="mt-4 w-100 rounded-pill"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  Submitting...
                </>
              ) : (
                'Submit'
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TailorMade;
