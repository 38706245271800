import React, { useEffect, useRef } from "react";
import "./CSS/Page4.css";
import sriLankaMap from "./img/sri-lanka-map.png"; // Replace with your map image path
import OmacGroup from "../Assets/OMACLOGO.png";

function Page4() {
  const mapRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const element = mapRef.current;
      if (element) {
        const scrollY = window.scrollY;
        const rotation = Math.min(scrollY / 5, 0); // Limit rotation to 360 degrees
        element.style.transform = `rotateY(${rotation}deg) rotateX(${
          rotation / 2
        }deg)`;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const reviews = [
    {
        name: "John Doe",
        subtitle: "Traveller",
        stars: 5,
        feedback:
            "Sri Lanka is a paradise! The tour was well-organized, and the sights were breathtaking.",
        date: "2024-11-01",
    },
    {
        name: "Jane Smith",
        subtitle: "Travel Blogger",
        stars: 4,
        feedback:
            "A truly memorable experience. The cultural diversity and the beaches were amazing!",
        date: "2024-11-10",
    },
    {
        name: "Samuel Green",
        subtitle: "Nature Enthusiast",
        stars: 4.5,
        feedback:
            "I loved the tea plantations and wildlife safaris. Highly recommended for nature lovers.",
        date: "2024-10-25",
    },
    {
        name: "Emily Johnson",
        subtitle: "Food Critic",
        stars: 5,
        feedback:
            "Sri Lanka’s cuisine is extraordinary! From spicy curries to sweet desserts, every bite was a delight.",
        date: "2024-11-12",
    },
    {
        name: "Michael Brown",
        subtitle: "Adventure Seeker",
        stars: 4.8,
        feedback:
            "Thrilling adventures! Climbing Sigiriya and diving off the coast were experiences I’ll never forget.",
        date: "2024-11-08",
    },
];


  const renderStars = (stars) => {
    const fullStars = Math.floor(stars);
    const halfStar = stars - fullStars >= 0.5;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
    return (
      <>
        {"★".repeat(fullStars)}
        {halfStar && "☆"}
        {"☆".repeat(emptyStars)}
      </>
    );
  };

  return (
    <div className="page3-container">
        {/* Header Title */}
      <div className="header-title">
        <h1>Explore the Beauty of Sri Lanka</h1>
      </div>
      <div className="row">
        {/* Feedback Slider */}
        <div className="col-md-6 feedback-section">
          <div className="feedback-slider">
            {reviews.map((review, index) => (
              <div className="feedback-card premium-card" key={index}>
                <div className="feedback-header">
                  <div>
                    <h3 className="client-name">{review.name}</h3>
                    <p className="client-subtitle">{review.subtitle}</p>
                  </div>
                  <p className="stars">{renderStars(review.stars)}</p>
                </div>
                <p className="feedback">{review.feedback}</p>
                <div className="feedback-footer">
                  <img
                    src={OmacGroup}
                    alt="Company Logo"
                    className="company-logo"
                  />
                  <p className="feedback-date">{review.date}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Map Section */}
        <div className="col-md-6 map-section">
          <div className="map-container">
            <img
              src={sriLankaMap}
              alt="Sri Lanka Map"
              className="sri-lanka-map"
              ref={mapRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page4;
