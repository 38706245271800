import React from "react";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page6 from "./Page6";
import Page7 from "./Page7";
import Page8 from "./Page8";

function AboutSL() {
  return (
    <>
      <div className="mb-4">
        <Page7 />
      </div>
      <div className="mb-4">
        <Page8 />
      </div>
      <div className="mb-4">
        <Page2 />
      </div>
      <div className="mb-4">
        <Page3 />
      </div>
      <div className="mb-4">
        <Page4 />
      </div>
      <div className="mb-4">
        <Page5 />
      </div>
      <div className="mb-4">
        <Page6 />
      </div>
      
    </>
  );
}

export default AboutSL;
