import React from "react";
import "./CSS/Page3.css";

// Import all images
import img1 from "./img/1100x320 (1).jpg";
import img2 from "./img/4-Nine-Arche-Bridge.jpg";
import img3 from "./img/ella.jpg";
import img4 from "./img/galary1.jpg";
import img5 from "./img/galary10.jpg";
import img6 from "./img/galary12.jpg";
import img7 from "./img/galary2.jpg";
import img8 from "./img/galary3.jpg";
import img9 from "./img/galary4.jpg";
import img10 from "./img/galary5.jpg";
import img11 from "./img/galary6.jpg";
import img12 from "./img/galary7.jpg";
import img13 from "./img/galary8.jpg";
import img14 from "./img/galary9.jpg";
import img15 from "./img/Galle-1024x768.jpg";
import img16 from "./img/galle.jpg";
import img17 from "./img/gallery1-2-4-1.jpg";
import img18 from "./img/kandy.jpg";
import img19 from "./img/nuwaraeliya.jpg";
import img20 from "./img/shutterstock_1084348016.webp";
import img21 from "./img/Sigiriya-rock-fortress-in-Sri-Lanka.webp";
import img22 from "./img/sigiriya.jpg";
import img23 from "./img/test1.jpg";
import img24 from "./img/test11.jpg";
import img25 from "./img/test2.jpg";
import img26 from "./img/test6.jpg";
import img27 from "./img/test7.jpg";
import img28 from "./img/test9.jpg";
import img29 from "./img/tourist-attractions-in-sri-lanka.jpg";
import img30 from "./img/Yala-1024x768.jpg";
import img31 from "./img/yala1.jpg";
import img32 from "./img/yala2.jpg";
import img33 from "./img/yala3.jpg";
import img34 from "./img/yala4.webp";

const images = [
  img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12,
  img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23,
  img24, img25, img26, img27, img28, img29, img30, img31, img32, img33, img34,
];

function Page3() {
  return (
    <div className="gallery-container">
      <h1 className="gallery-title">Sri Lanka Gallery</h1>
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image} alt={`Gallery ${index + 1}`} className="gallery-img" />
            <div className="gallery-overlay">
              <p className="gallery-text">Sri Lanka</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Page3;
