import React from 'react';
import RichHistoryandCulture from './img/Rich History and Culture in sri lanka.jpg';
import SpectacularBeaches from './img/Spectacular Beaches.jpg';
import ExquisiteWildlife from './img/Exquisite Wildlife and Nature.jpg';
import TeaPlantations from './img/Tea Plantations and Scenic Landscapes.jpeg';
import SriLankanCuisine from './img/Delicious Sri Lankan Cuisine.jpg';
import AdventureActivities from './img/Adventure Activities.jpg';

function Page2() {
  return (
    <div className="container-fluid p-0">
      {/* Hero Section */}
      <header className="text-center text-white py-5" style={{ background: 'linear-gradient(45deg, #23a6d5, #23d5ab)' }}>
        <h1 className="display-3 fw-bold">Why Visit Sri Lanka?</h1>
        <p className="lead mt-3">
          Explore the paradise island known for its pristine beaches, rich culture, and breathtaking landscapes.
        </p>
        <a href="#main-content" className="btn btn-light btn-lg mt-4 shadow-sm">
          Discover More
        </a>
      </header>

      {/* Main Content */}
      <div id="main-content" className="container my-5">
        <div className="row g-4">
          {/* Rich History and Culture */}
          <div className="col-md-6 col-lg-4">
            <div className="card h-100 border-0 shadow-lg hover-zoom">
              <div className="position-relative">
                <img
                  src={RichHistoryandCulture}
                  className="card-img-top rounded-top"
                  alt="Rich History and Culture"
                />
                <div className="position-absolute top-0 end-0 p-3">
                  <i className="bi bi-archive-fill text-white fs-3"></i>
                </div>
              </div>
              <div className="card-body bg-light">
                <h5 className="card-title fw-bold">Rich History and Culture</h5>
                <p className="card-text">
                  Discover Sri Lanka’s 2,500-year-old heritage through UNESCO World Heritage Sites and colorful festivals.
                </p>
              </div>
            </div>
          </div>

          {/* Spectacular Beaches */}
          <div className="col-md-6 col-lg-4">
            <div className="card h-100 border-0 shadow-lg hover-zoom">
              <div className="position-relative">
                <img
                  src={SpectacularBeaches}
                  className="card-img-top rounded-top"
                  alt="Spectacular Beaches"
                />
                <div className="position-absolute top-0 end-0 p-3">
                  <i className="bi bi-water text-white fs-3"></i>
                </div>
              </div>
              <div className="card-body bg-light">
                <h5 className="card-title fw-bold">Spectacular Beaches</h5>
                <p className="card-text">
                  Relax on pristine beaches like Mirissa and Arugam Bay or dive into thrilling water sports.
                </p>
              </div>
            </div>
          </div>

          {/* Exquisite Wildlife and Nature */}
          <div className="col-md-6 col-lg-4">
            <div className="card h-100 border-0 shadow-lg hover-zoom">
              <div className="position-relative">
                <img
                  src={ExquisiteWildlife}
                  className="card-img-top rounded-top"
                  alt="Exquisite Wildlife"
                />
                <div className="position-absolute top-0 end-0 p-3">
                  <i className="bi bi-tree text-white fs-3"></i>
                </div>
              </div>
              <div className="card-body bg-light">
                <h5 className="card-title fw-bold">Exquisite Wildlife and Nature</h5>
                <p className="card-text">
                  Embark on safaris and spot majestic wildlife in lush rainforests and serene national parks.
                </p>
              </div>
            </div>
          </div>

          {/* Tea Plantations */}
          <div className="col-md-6 col-lg-4">
            <div className="card h-100 border-0 shadow-lg hover-zoom">
              <div className="position-relative">
                <img
                  src={TeaPlantations}
                  className="card-img-top rounded-top"
                  alt="Tea Plantations"
                />
                <div className="position-absolute top-0 end-0 p-3">
                  <i className="bi bi-cup-hot text-white fs-3"></i>
                </div>
              </div>
              <div className="card-body bg-light">
                <h5 className="card-title fw-bold">Tea Plantations and Scenic Landscapes</h5>
                <p className="card-text">
                  Wander through picturesque tea estates and enjoy breathtaking views of misty hills.
                </p>
              </div>
            </div>
          </div>

          {/* Sri Lankan Cuisine */}
          <div className="col-md-6 col-lg-4">
            <div className="card h-100 border-0 shadow-lg hover-zoom">
              <div className="position-relative">
                <img
                  src={SriLankanCuisine}
                  className="card-img-top rounded-top"
                  alt="Sri Lankan Cuisine"
                />
                <div className="position-absolute top-0 end-0 p-3">
                  <i className="bi bi-egg-fried text-white fs-3"></i>
                </div>
              </div>
              <div className="card-body bg-light">
                <h5 className="card-title fw-bold">Delicious Sri Lankan Cuisine</h5>
                <p className="card-text">
                  Treat your taste buds to bold and aromatic flavors with dishes like hoppers and sambols.
                </p>
              </div>
            </div>
          </div>

          {/* Adventure Activities */}
          <div className="col-md-6 col-lg-4">
            <div className="card h-100 border-0 shadow-lg hover-zoom">
              <div className="position-relative">
                <img
                  src={AdventureActivities}
                  className="card-img-top rounded-top"
                  alt="Adventure Activities"
                />
                <div className="position-absolute top-0 end-0 p-3">
                  <i className="bi bi-activity text-white fs-3"></i>
                </div>
              </div>
              <div className="card-body bg-light">
                <h5 className="card-title fw-bold">Adventure Activities</h5>
                <p className="card-text">
                  Satisfy your adventurous spirit with activities like rafting, mountain biking, and scuba diving.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Page2;
