import React from "react";
import { BackgroundLines } from "./ui/background-lines";

export function BackgroundLinesDemo() {
  return (
    <BackgroundLines className="position-relative w-100 min-vh-100 d-flex align-items-center justify-content-center">
      <div className="text-center" >
        <h2 className="fw-bold text-dark py-2 display-4">
          OMAC GROUPS
        </h2>
        <p className="text-secondary fw-light small">
          Get the best advice from our experts, including expert artists, painters, marathon enthusiasts, and RDX, totally free.
        </p>
      </div>
    </BackgroundLines>
  );
}
