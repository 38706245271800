import React from "react";
import './CSS/Page7.css'; // Ensure the CSS file for styling is present
import backgroundVideo from './Video/videoplayback.mp4'; // Update the path accordingly

function Page7() {
  return (
    <div className="background-video-container">
      <video className="background-video" autoPlay loop muted>
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlay">
        <h1 className="main-title">Discover Sri Lanka</h1>
        <p className="subtitle">Your gateway to lush landscapes, rich history, and vibrant culture.</p>
      </div>
    </div>
  );
}

export default Page7;
