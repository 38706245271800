import React from "react";
import './CSS/Page5.css';
import TourGuide from "./img/TourGuide.jpg";
import TourTips from "./img/TourTips.jpg";
import Wildlife from "./img/Wildlife.jpg";

function Page5() {
  const articles = [
    {
      title: "A Beach Lover's Guide to Sri Lanka: Top Coastal Destinations",
      excerpt: "Sri Lanka's coastline is a paradise for beach lovers. From the golden sands of Unawatuna to the surfing haven of Arugam Bay, discover the best beach destinations on the island.",
      date: "November 15, 2024",
      image: TourGuide
    },
    {
      title: "Exploring the Cultural Triangle: An Itinerary for History Buffs",
      excerpt: "Step back in time and explore Sri Lanka’s Cultural Triangle. Visit ancient cities like Anuradhapura, Polonnaruwa, and Sigiriya, where history and culture come alive.",
      date: "November 14, 2024",
      image: TourTips
    },
    {
      title: "Wildlife Adventures in Sri Lanka: National Parks and Wildlife Safaris",
      excerpt: "Sri Lanka is home to some of the most diverse wildlife in Asia. Explore its national parks, from Yala to Udawalawe, and embark on thrilling wildlife safaris to witness exotic animals in their natural habitat.",
      date: "November 13, 2024",
      image: Wildlife
    },
  ];

  return (
    <div className="news-containerr">
      <h2 className="news-title text-center">Latest News & Articles</h2>
      <div className="news-grid">
        {articles.map((article, index) => (
          <div key={index} className="news-card">
            {article.image ? (
              <img src={article.image} alt={article.title} className="news-image" />
            ) : (
              <div className="news-content">
                <h3 className="news-card-title">{article.title}</h3>
                <p className="news-card-excerpt">{article.description}</p>
                <a 
                  href={article.url} 
                  className="news-card-link" 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  Read More
                </a>
              </div>
            )}
            {article.image && (
              <div className="news-content">
                <h3 className="news-card-title">{article.title}</h3>
                <p className="news-card-excerpt">{article.excerpt}</p>
                <p className="news-card-date">{article.date}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Page5;
