import confetti from 'canvas-confetti';

/**
 * Triggers a fullscreen confetti effect on the entire page.
 * @param {Object} options - Optional settings for the confetti.
 */
export default function confettiEffect(options = {}) {
    const defaults = {
        angle: 90,
        spread: 120,
        startVelocity: 45,
        particleCount: 200,
        decay: 0.9,
        colors: ['#ff0a54', '#ff477e', '#ff7096', '#ff85a1', '#fbb1bd'],
    };

    const settings = { ...defaults, ...options };

    // Create a confetti instance that targets the entire screen
    const myCanvas = document.createElement('canvas');
    myCanvas.style.position = 'fixed';
    myCanvas.style.top = '0';
    myCanvas.style.left = '0';
    myCanvas.style.width = '100%';
    myCanvas.style.height = '100%';
    myCanvas.style.zIndex = '9999';
    myCanvas.style.pointerEvents = 'none'; // Make it non-interactive
    document.body.appendChild(myCanvas);

    const fullScreenConfetti = confetti.create(myCanvas, { resize: true });

    // Trigger the confetti effect
    fullScreenConfetti({
        angle: settings.angle,
        spread: settings.spread,
        startVelocity: settings.startVelocity,
        particleCount: settings.particleCount,
        decay: settings.decay,
        colors: settings.colors,
    });

    // Clean up the canvas after the confetti is done
    setTimeout(() => {
        document.body.removeChild(myCanvas);
    }, 5000); // Remove canvas after 5 seconds
}
