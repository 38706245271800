import React from "react";
import "./CSS/Page8.css"; // Import external CSS
import Card from "./img/sigiriya.jpg";

const Page8 = () => {
  return (
    <div className="page8-container">
      {/* Main Content Section */}
      <div className="page8-content">
        {/* Text Section */}
        <div className="page8-text">
          <h4 className="page8-highlight">The Pearl of the Indian Ocean</h4>
          <h1 className="page8-title">
            Discover the Ultimate Travel Experience in Sri Lanka
          </h1>
          <p className="page8-description">
            If you’ve ever read a book and skipped its most thrilling chapter,
            you might compare it to overlooking Sri Lanka in your travel plans.
            On a map, Sri Lanka may appear as a small mango-shaped gem in South
            Asia, almost hidden. But within this tiny island lies a universe of
            biodiversity that leaves the world in awe.
          </p>
          <p className="page8-description">
            With centuries of influence from Portuguese, Dutch, and British
            colonial eras, Sri Lanka's rich cultural heritage is not diminished
            but instead enhanced. From ancient temples to lush tea plantations,
            every corner of this island tells a story that’s vibrant, diverse,
            and timeless.
          </p>
          <p className="page8-description">
            At <strong>OMAC GROUP</strong>, we believe in curating experiences
            that transcend traditional travel. Whether you're seeking adventure,
            tranquility, or a deeper cultural connection, our expertise ensures
            that your journey to Sri Lanka becomes an unforgettable chapter in
            your life’s story.
          </p>
          {/* Uncomment below for a CTA */}
          {/* <button className="page8-button">
    Learn More <span className="arrow">➔</span>
  </button> */}
          <p className="page8-info">
            <span className="info-icon">👤</span>
            <span className="info-highlight">2,500+</span> People Booked
            Tomorrow Land Event in the Last 24 hours
          </p>
        </div>

        {/* Image Section */}
        <div className="page8-image">
          <img src={Card} alt="Sri Lanka" />
        </div>
      </div>

      {/* Statistics Section */}
      <div className="page8-stats">
        <div className="stat-box">
          <h3 className="stat-number">150k</h3>
          <p className="stat-label">Happy Traveler</p>
        </div>
        <div className="stat-box">
          <h3 className="stat-number">95.7%</h3>
          <p className="stat-label">Satisfaction Rate</p>
        </div>
        <div className="stat-box">
          <h3 className="stat-number">5000+</h3>
          <p className="stat-label">Tour Completed</p>
        </div>
      </div>
    </div>
  );
};

export default Page8;
