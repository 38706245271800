import Heading from "../components/common/Heading";
import TailorMade from "../components/home/TailorMade";

function TailorMadePage() {
  return (
    <>
    <Heading heading="TAILER-MADE TOURS" title="Home" subtitle="Plan your tour"/>
    <TailorMade/>
    </>
  )
}

export default TailorMadePage