import React from "react";
import Ella from "./img/ella.jpg";
import Beach from "./img/galary7.jpg";
import Ella1 from "./img/4-Nine-Arche-Bridge.jpg";

function Page1() {
  return (
    <>
      {/* Hero Section */}
      <div
        className="position-relative text-center text-white"
        style={{
          height: "100vh",
          background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)), url(${Ella})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="position-absolute top-50 start-50 translate-middle"
          style={{ zIndex: 2 }}
        >
          <h1 className="display-1 fw-bold mb-3 text-white">Discover Sri Lanka</h1>
          <p className="fs-4">
            Your gateway to lush landscapes, rich history, and vibrant culture.
          </p>
          <a
            href="#explore"
            className="btn btn-success btn-lg mt-4 px-5 rounded-pill"
            style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)" }}
          >
            Explore Now
          </a>
        </div>
      </div>

      {/* Explore Section */}
      <div id="explore" className="container py-5">
        <div className="text-center mb-5">
          <h2 className="fw-bold text-success">Explore Sri Lanka</h2>
          <p className="text-muted">
            A breathtaking blend of natural beauty and cultural treasures.
          </p>
        </div>
        <div className="row g-4">
          {/* Card 1 */}
          <div className="col-lg-4 col-md-6">
            <div
              className="card border-0 shadow-sm h-100"
              style={{
                borderRadius: "15px",
                overflow: "hidden",
                transition: "transform 0.3s",
              }}
            >
              <img
                src={Ella1}
                alt="Ella"
                className="card-img-top"
                style={{ objectFit: "cover", height: "200px" }}
              />
              <div className="card-body">
                <h5 className="card-title text-success">Ella's Beauty</h5>
                <p className="card-text">
                  Famous for its lush landscapes and Nine Arches Bridge, Ella is
                  a paradise for nature lovers.
                </p>
                <a
                  href="#"
                  className="text-success text-decoration-none fw-bold"
                >
                  Learn More →
                </a>
              </div>
            </div>
          </div>

          {/* Card 2 */}
          <div className="col-lg-4 col-md-6">
            <div
              className="card border-0 shadow-sm h-100"
              style={{
                borderRadius: "15px",
                overflow: "hidden",
                transition: "transform 0.3s",
              }}
            >
              <img
                src={Beach}
                alt="Beach"
                className="card-img-top"
                style={{ objectFit: "cover", height: "200px" }}
              />
              <div className="card-body">
                <h5 className="card-title text-success">Coastal Serenity</h5>
                <p className="card-text">
                  Unwind on Sri Lanka's pristine beaches and soak in the
                  tropical sun.
                </p>
                <a
                  href="#"
                  className="text-success text-decoration-none fw-bold"
                >
                  Learn More →
                </a>
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className="col-lg-4 col-md-6">
            <div
              className="card border-0 shadow-sm h-100"
              style={{
                borderRadius: "15px",
                overflow: "hidden",
                transition: "transform 0.3s",
              }}
            >
              <img
                src={Ella}
                alt="Temple"
                className="card-img-top"
                style={{ objectFit: "cover", height: "200px" }}
              />
              <div className="card-body">
                <h5 className="card-title text-success">Cultural Wonders</h5>
                <p className="card-text">
                  Dive into Sri Lanka's rich history and ancient temple
                  architecture.
                </p>
                <a
                  href="#"
                  className="text-success text-decoration-none fw-bold"
                >
                  Learn More →
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Page1;
